'use client';

import Cookies from 'js-cookie';
import Script from 'next/script';
import { useEffect, useState } from 'react';

export const GTMHead = () => {
  const [gtmID, setGtmID] = useState<string | null>(null);

  useEffect(() => {
    const gtmFromCookie = Cookies.get('GTM_ID');
    setGtmID(gtmFromCookie || 'GTM-KG2X6QQ6');
  }, []);

  if (!gtmID) return null;

  return (
    <Script
      id='google-tag-manager'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmID}');
      `,
      }}
    />
  );
};

export const GTMBody = () => {
  const [gtmID, setGtmID] = useState<string | null>(null);

  useEffect(() => {
    const gtmFromCookie = Cookies.get('GTM_ID');
    setGtmID(gtmFromCookie || 'GTM-KG2X6QQ6');
  }, []);

  if (!gtmID) return null; // Không render nếu chưa có GTM ID

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmID}`}
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );
};
